<template>
  <div>
    <!-- <div class="title">
      <i class="el-icon-arrow-left icon" @click="$router.go(-1)"></i>任务结算
    </div> -->
    <el-card class="main"  v-loading.fullscreen="loading1"  :element-loading-text="loadingtext">
      <div class="nav">
        <div class="jindu jindu1">上传结算列表</div>
        <div class=" jindu jindu2">上传结算单</div>
        <div class="jindu jindu3">提交结算申请</div> -->
      </div>
      <div style="margin-top:10px">
        <span style="font-size: 16px">{{ Detailstr.taskName }}</span>
        <span class="mini">{{Detailstr.taskStateName}}</span>
      </div>
      <div class="two">
        起始日期 {{ Detailstr.taskStartTime }} ~ {{ Detailstr.taskEndTime }}
        <span class="listspan"
          >结算周期 {{ Detailstr.settlementCycleName }}</span
        >
        <span class="listspan"
          >结算标准 {{ Detailstr.price }}
          {{ Detailstr.settlementStandardName }}</span
        >
        <!-- <span class="listspan">所属行业 {{ Detailstr.industryName }}</span> -->
      </div>
      <div class="bott">
         <el-upload
            class="upload-demo"
            :auto-upload="false"
            :on-change="changeUploadCoverImg"
            :before-remove="beforeRemove"
            :show-file-list="true"
            accept=".xlsx, .xls"
            ref="upload"
            :limit="1"
            action>
            <el-button   type="primary" size="mini" >选择文件</el-button>
          </el-upload>
          <p>支持上传xlsx、xls格式的文件
            <el-button   type="text" size="mini"  @click="download1()">下载《{{Detailstr.taskName}}结算模板》</el-button>
          </p>
           <!-- <el-button  type="primary" size="mini" @click="upStatement()">上传结算单</el-button>  -->
           
      </div>
      <!-- xia -->
      <div class="bott">
            <el-button  v-if="upflag" type="primary" size="mini" @click="updatalist()" :loading="uploading">上传列表</el-button> 
            <el-button  v-if="upflag1" type="primary" size="mini" @click="validation()">数据验证</el-button> 
            <el-button  v-if="upflag2" type="primary" size="mini" @click="upStatement()">上传结算单</el-button> 
      </div>
      <p style="color:red;font-size:14px" v-if="flag">数据验证需要全部验证通过后才可以进行发放，如有验证失败的数据，请修改后再次验证</p>
      <!-- 数据验证显示 -->
      <div class="datanum" v-if="datanumflag">
          <el-row>
            <el-col :span="3"><div>验证通过</div></el-col>
            <el-col :span="3"><div>验证未通过</div></el-col>
          </el-row>
          <el-row>
            <el-col :span="3"><div>人数：{{verification.passNum}}</div></el-col>
            <el-col :span="3"><div>人数：{{verification.failNum}}</div></el-col>
          </el-row>
           <el-row>
            <el-col :span="3"><div>金额：{{verification.passAmount}}</div></el-col>
            <el-col :span="3"><div>金额：{{verification.failAmount}}</div></el-col>
          </el-row>
      </div>
      <!-- 搜索条件 -->
      <div class="datanum" v-if="datanumflag">
        <span>验证结果</span>
        <el-select v-model="validationResults" placeholder="请选择" style="width:160px;margin-left:20px" @change="changelist()">
            <el-option
              v-for="item in validationResultsArr"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
        </el-select> 
         <el-button style=";margin-left:20px" type="primary" size="mini" @click="exportlist()">导出</el-button> 
           
      </div>
      
      <div  v-if="tableflag">
         <el-table
      v-loading="load"      
      :header-cell-style="{background:'#9e99b9',color:'#ffff'}"
      :data="tableData"
      style="width: 100%;margin-top: 20px">
      <el-table-column
        header-align="center"
        align="center"
        prop="errMsg"
        label="错误信息" >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="验证结果"
        >
        <template slot-scope="scope">{{scope.row.checkState == 1 ? '通过' : '未通过'}}</template>
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="name"
        label="姓名">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="idCard"
        label="身份证号">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="creditCode"
        label="统一社会代码">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="amount"
        label="金额">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="price"
        :label="'结算单价 (' + Detailstr.settlementStandardName+')'">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="workload"
        width="130"
        label="数量">
      </el-table-column>
       <el-table-column
       header-align="center"
        align="center"
        prop="remarks"
        width="130"
        label="备注">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="statusText"
        width="130"
        label="操作">
         <template slot-scope="scope">
          <el-button  type="text" size="small" @click="change(scope.row)" v-if="scope.row.checkState!=1">编辑</el-button>
          <el-button  type="text" size="small" @click="remove(scope.row.orderItemId)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
     <el-pagination
            background
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="currPage"
            :page-sizes="[10, 20,30, 50, 100]"
            :page-size="pageSize"
            :total="totalCount"
            class="text_center"
            layout="total,  prev, pager, next, sizes,jumper"
             style="text-align:right;margin-top:30px"
     ></el-pagination> 
     <!-- 编辑 -->
     <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      width="30%"
       id="dia"
       center
      :before-close="handleClose">
           <div style="transform:translateY(-30px)">
       </div>
        <el-form ref="dataForm" :model="dataForm" label-width="140px" class="form">
            <el-form-item label="姓名">
                <el-input v-model="dataForm.name" ></el-input>
            </el-form-item>
            <el-form-item label="身份证号">
                <el-input v-model="dataForm.idCard" ></el-input>
            </el-form-item>
            <el-form-item label="金额" style="text-align:center">
              <el-input-number  v-model="dataForm.amount" :min="0" :controls="false" :precision="2" @blur="blueamout(dataForm.amount)" ></el-input-number>
            </el-form-item>
            <el-form-item label="数量" style="text-align:center">
               <el-input-number  v-model="dataForm.num" :min="0" :controls="false" :precision="2" disabled></el-input-number>
                <!-- <el-input v-model="undefined" disabled></el-input> -->
            </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="surechange">确认修改</el-button>
      </span>
    </el-dialog>
      </div>
     </el-card>
  </div>
</template>

<script>
import { export2Excel } from '@/utils/util'
import { getDetail,tempUpload,downloadExcel,taskOrderItemPageList,uploadOrderExcel,deleteOrder,edit,numValidation,GoToUpload,getOrderItemCheckResult} from "@/api/task";
export default {
  props:['orderId'],
  data() {
    return {
      load:false,
      dialogVisible: false,
      Detailstr: {},
      file:"",
      fileId:"",
      upflag:false,
      upflag1:false,
      upflag2:false,
      tableflag:false,
      datanumflag:false,//数据验证显示
      orderBatchId:"333",//订单id
      tableData: [],
        pageSize: 10,
        totalCount: 0,//数据长度
        currPage: 1,
      dataForm:{
        orderItemId:"",
        name:"",
        taskId:"",//任务id
        idCard:"",
        amount:"",
        num:""
      },
      verification:{},//数据验证
      validationResultsArr:[
        {
          value: '',
          label: '全部'
        }, {
          value: '1',
          label: '通过'
        },{
          value: '2',
          label: '未通过'
        }
      ],//验证结果数组
      validationResults:"",//验证结果
      exportList:[],//导出数组
      columns:[
         {
            title:"错误信息",
            key:"errMsg"
          },
           {
            title:"验证结果",
            key:"checkState"
          },
           {
            title:"姓名",
            key:"name"
          },
           {
            title:"身份证号",
            key:"idCard"
          },
           {
            title:"统一社会代码",
            key:"creditCode"
          },
           {
            title:"金额",
            key:"amount"
          },
           {
            title:"结算单价(元/天)",
            key:"price"
          },
          {
            title:"数量",
            key:"workload"
          },
          {
            title:"备注",
            key:"remarks"
          }],//导出表格
          flag:false,//数据验证提示
          uploading:false,//上传列表
          loadingtext:"加载中...",
          fullscreenLoading: false,
          timer:"",
          loading1:false
    };
  },

// computed:{
//     application(){
//         return this.dataForm.amount
//     }        
// },
// watch:{
//     application:function(val){
//          console.log(val,"p3")
//     }
// },
   created() {},
   mounted(){
      // var timer=setInterval(()=>{
      //        console.log(1);
      //       },1000)
      //       setTimeout(()=>{
      //        clearInterval(timer)
      //       },5000)
      //  return     
    this.Detail();
    if (this.$route.query.orderId) {
      setTimeout(item=>{
        this.orderBatchId=this.$route.query.orderId
       this.validation()
       this.list()
       this.upflag=this.upflag1=this.upflag2=this.datanumflag=this.tableflag=true
      },100)
    }
   },
  activated() {
  },
  methods: {
    // 上部详情
    Detail() {
     getDetail({
        taskId: this.$route.query.taskId,
      }).then((data) => {
        if (data && data.code == 200) {
          this.Detailstr = data.data;
        }
      });

    },
    //上传
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    changeUploadCoverImg(event) {
        this.file = event.raw;
        // this.name = event.raw.name
        let params = new FormData();
        params.append("fileTypeCode", 100301 );
        params.append("auth", true);
        params.append("multipartFile", this.file);
        tempUpload({ params}).then((data) => {
         if(data.code == 200) {
            this.upflag=true,
            this.fileId = data.data.id
          } 
        })
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      download1(){
       downloadExcel({
          taskId:this.$route.query.taskId
        }).then((data=>{
          let regFileNames = data.headers['content-disposition'].match(/=(.*)$/)[1] //获取到Content-Disposition; 截取=后面的文件名称
          let fileName = window.decodeURI(regFileNames, "UTF-8");
            const blob = new Blob([data.data],{type:'application/vnd.ms-excel'})
            if ('download' in document.createElement('a')) {
              const elink = document.createElement('a')
              elink.download = fileName
              elink.style.display = 'none'
              elink.href = URL.createObjectURL(blob)
              document.body.appendChild(elink)
              elink.click()
              URL.revokeObjectURL(elink.href)
              document.body.removeChild(elink)
            } else {
              navigator.msSaveBlob(blob,fileName)
            }
        }))
      },
      updatalist(){
        this.uploading=true
        uploadOrderExcel({
          fileId:this.fileId,
          taskId:this.$route.query.taskId,
        }).then(data=>{
          if (data && data.code==200) {
             this.uploading=false
            this.loading1=true
            this.timer = setInterval(this.resule,1000);
            this.tableflag=this.upflag1=true
            this.orderBatchId=data.data.orderBatchId
          }
        })
      },
      resule(){
         getOrderItemCheckResult(this.orderBatchId).then(data=>{
          if (data && data.code==200) {
             this.loading1=false;
            clearInterval(this.timer);
            this.list()
           return
          } 
         this.loadingtext=data.msg
        })        
      },
      remove(id){
        deleteOrder(id).then(data=>{
          if (data && data.code==200) {
             this.validation()
             this.list()
          }
        })
      },
       // 搜索
      changelist(){
         this.currPage=1
         this.list()
      },
      // 列表
      list(){
        // console.log(this.Detailstr,"p");
        taskOrderItemPageList({
          "validationResults":this.validationResults,
          "orderBatchId":this.orderBatchId,
          "industryCode": this.Detailstr.industryCode,
          "current": this.currPage,
          "size": this.pageSize,
        }).then(data=>{
          if (data && data.code==200) {
            this.totalCount=data.data.total
             this.tableData=data.data.records
          }
        })
      },
       // 每也数
      sizeChangeHandle(val) {
        this.pageSize = val;
        this.currPage = 1;
        this.list();
      },
      // 当前页
      currentChangeHandle(val) {
        this.currPage = val;
        this.list();
      },
      blueamout(val){
        let value=val/this.dataForm.price
        this.$set(this.dataForm,"num",value) 
      },
      // 点击编辑
      change(item){
        this.dialogVisible=true
        this.dataForm.name=item.name
        this.dataForm.idCard=item.idCard
        this.dataForm.amount=this.delcommafy(item.amount)
        this.dataForm.num=this.delcommafy(item.workload)
        this.dataForm.price=this.delcommafy(item.price)
         this.dataForm.orderItemId=item.orderItemId
        
      },
      delcommafy(num) {
        if (num != undefined) {
          num = num.toString();
          num = num.replace(/[ ]/g, ""); //去除空格  
          num = num.replace(/,/gi, '');
          return Number(num);
        }
      },
      // 确定编辑
      surechange(){
        console.log(this.dataForm.amount,"33");
        edit({
          name:this.dataForm.name,
          orderItemId:this.dataForm.orderItemId,
          taskId:this.$route.query.taskId,
          idCard:this.dataForm.idCard,
          amount:this.dataForm.amount,
        }).then(data=>{
          if (data && data.code==200) {
             this.dialogVisible = false
             this.validation()
             this.list();
          }
        })
          
      },
      // 关闭编辑
   handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    // 数据验证
    validation(){
      // this.orderBatchId=46
      numValidation(this.orderBatchId).then(data=>{
         if (data && data.code==200) {
            this.verification= data.data
            this.datanumflag=this.upflag2=true
          }
      })
    },
    // 导出list
    exportlist(){
      let info = {
          "validationResults":this.validationResults,
          "orderBatchId":this.orderBatchId,
          "industryCode":this.Detailstr.industryCode,
          "current": 1,
          "size": 9999,
        }
      taskOrderItemPageList(info).then(data =>{
        let condition=""
        if (data.data.records!="" && data.code==200) {

              let orderNumber= data.data.records[0].batchNo
              this.exportList = data.data.records
               this.exportList.map(item=>{
                if (item.checkState==1) {
                   item.checkState="通过"
                   return
                }
                 item.checkState="未通过"
              })
              if (this.validationResults==1) {
                condition="通过"
              }else if (this.validationResults==2) {
                condition="未通过"
              }
              //  condition= data.data.records[0].checkState
               export2Excel(this.columns,this.exportList,this.Detailstr.taskName + orderNumber + condition)
           }
       })
    },
    // 上传结算单
    upStatement(){
    if (this.verification.failNum>0) {
        // this.$forceUpdate()
          this.flag=true
          return
      }
       this.flag=false
        GoToUpload(this.orderBatchId).then(data=>{
         if (data && data.code==200) {
           if (data.succ==true) {
             this.$emit("Flagclick1", false,this.orderBatchId);
           }
          }
      })
      //  this.$bus.$emit('orderId2',666)
      //  console.log(this.$bus,"emit时候");
     
    }
   },
};
</script>

<style  scoped>
.main {
  margin-top: 10px;
}
.mini {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  text-align: center;
  width: 60px;
  border: 1px solid #9e99b9;
  border-radius: 25px;
  margin-left: 14px;
  transform: translateY(-2px);
  font-size: 10px;
}
.two {
  font-size: 13px;
  margin-top: 10px;
}
.listspan {
  margin-left: 30px;
}
.content-view {
  margin-top: 20px;
}
.content-view /deep/.el-progress-bar {
  width: 80%;
}
.content-view /deep/ .el-progress__text {
  font-size: 10px !important;
}
.nav {
  width: 110%;
  height: 35px;
  line-height: 32px;
  text-align: center;
  margin-top: -20px;
  margin-left: -20px;
  color: #ffff;
  font-size: 13px;
  /* background: darkcyan; */
  
}
.nav div{
 display: inline-block;
 height: 32px;
  width: 530px;
  background-size: cover;
}
.jindu1 {
  
  background-image: url("~pub/img/a1.png");
  margin-left: -106px;
  
}

.jindu2 {
  background-image: url("~pub/img/a2.png");
   margin-left: 84px;
}
.jindu3 {
   margin-left: 84px;
  background-image: url("~pub/img/a3-2.png");
 
}
.icon12 {
  height: 100px;
  width: 500px;
  font-size: 50px;
}
/* 上传name一行显示 */
.upload-demo >>> .el-upload-list{ 
  display: inline-block; transform: translateY(8px) 
  }
  .bott{
    transform: translateY(10px);
  }
  /* 表格------------- */
  .main /deep/ #dia .form {
    margin-top:10px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
}
 #dia  .el-form-item {
  margin-bottom: 0px;
  border-bottom: 1px solid #ccc;
}
div /deep/ #dia  .el-input__inner {
  border: #fff;
}
div /deep/ #dia .el-form-item__label {
    text-align: center;
    background: #f0f0f0;
    /* // color: black; */
    border-right: 1px solid #ccc;
}
div /deep/ #dia .el-input.is-disabled .el-input__inner {
    text-align: center;
    background: #FFF;
    color: black;
}
div /deep/ #dia .el-input .el-input__inner {
    text-align: center;
    background: #FFF;
    color: black;
}
/* end */
.datanum{
  font-size: 14px;
  margin-top: 40px;
}
</style>


